import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from './navbar';
import {Link} from "react-scroll/modules";
function App() {
  return (
    <div className="App">
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <header className="App-header">
        </header>
        <div className="wrapper">
            <Navbar />
            <section className="focus" id="description">
                <div>
                    <h1 className="title titleHeader">Societal Readiness Thinking Tool</h1>
                </div>
                <p className="paragraph">
                    Hi, and welcome to the Societal Readiness Thinking Tool.
                    The thinking tool offers practical guidance for researchers
                    who wish to mature the societal readiness of their work.
                    The primary goal is to help researchers align their project
                    activities with societal needs and expectations. The thinking
                    tool asks reflective questions to stimulate thinking about how to
                    integrate ideas about
                    &nbsp;
                    <Link
                        className="link"
                        activeClass="active"
                        to="methods"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration= {500}
                    >responsible research and innovation</Link>
                    &nbsp;
                    into research practice,
                    at different stages in the project life. We have designed the tool so that it is
                    useful for researchers engaged in new as well as ongoing projects. Some of the reflective
                    questions used in the tool are adapted from other RRI projects.
                    References for these projects and a detailed account of the tool’s underlying methodology is available &nbsp;
                    <a href="Deliverable_6.1_Final_April 30_THINKING_TOOL.pdf" target="_blank" rel="noopener noreferrer" className="link">
                        here
                    </a>.
                    &nbsp;
                    If your project involves several researchers,
                    we recommend that the full team is involved in using the Societal Readiness Thinking Tool together,
                    and that you reserve sufficient time for discussions along the way.
                    Ideally, the team would use the tool from the from the earliest phases of the project and return at later stages thougout the project life.
                    You can learn more about the tool’s RRI terminology &nbsp;
                    <Link
                        className="link"
                        activeClass="active"
                        to="methods"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration= {500}
                    >here</Link>.
                </p>
                <div>
                    <h2 className="subtitle">How to use the tool:</h2>
                </div>
                <p className="paragraph">
                    To use the tool, start by registering your project.
                    Registering allows you to save your work and continue at a later point in time.
                    The tool can also be used without registration. Next, select your project’s current research phase.
                    Then select if you want to use the tool based on a specified entry point (i.e. your specific motivation for using the tool),
                    or tick off the
                    &nbsp;
                    <Link
                        className="link"
                        activeClass="active"
                        to="methods"
                        spy={true}
                        smooth={true}
                        offset={-50}
                        duration= {500}
                    >RRI-related keys or conditions </Link>
                    &nbsp;
                    that you wish to focus on. When you are done using the tool,
                    you can download a summary of your work in a PDF file. The thinking tool includes ‘tool-tips’ to help you navigate the tool.
                    It also includes a selection of methods and resources that might inspire your own work.
                    See our three-minute
                    &nbsp;
                    <a href="https://youtu.be/HdH8qSUy4Mk" target="_blank" rel="noopener noreferrer" className="link">
                        introductory video
                    </a>
                    &nbsp;
                    for further instructions on how to use the tool.
                </p>
                <iframe
                    title="tutorial"
                    id="video"
                    className="video"
                    width="1120"
                    height="630"
                    src="https://www.youtube.com/embed/HdH8qSUy4Mk"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />
            </section>
            <section className="focus" id="tool">
                <div className="buttoncontainer">
                    <a
                        className="button active"
                        href="https://cfa-research.au.dk/thinkingtool/tool/"
                        rel="noopener noreferrer"
                        target="_blank"
                        role="button"
                        aria-pressed="true"
                    >
                        Try the tool
                    </a>
                </div>
            </section >
            <section className="bottom-section" id="methods">
                <div>
                    <h1 className="title">What is Responsible Research and Innovation?</h1>
                </div>
                <p className="paragraph">
                    At its core, responsible research and innovation (RRI) is about aligning scientific knowledge production with broader societal needs and expectations.
                    It confers new responsibilities on scientists by committing them to reflect carefully upon the societal implications of their work.
                    Building on key contributions in the RRI-literature, the thinking tool distinguishes two interrelated approaches to responsibility: conditions and keys.
                </p>
                <div>
                    <h2 className="subtitle">Conditions</h2>
                </div>
                <p className="paragraph">
                    The <i>conditions</i> approach differentiates four “devises for reflection” that may help give shape to the research process by cultivating a forward-looking perspective on responsibility.
                </p>
                <div className="infobox">
                    <div className="listContainer">
                        <ul>
                            <li className="listItem">
                                <b>Anticipation</b> is about carefully examining both the intended and possible unintended consequences arising from research activities, including environmental, health-related, economic and social impacts. Anticipatory processes prompt “what if…?” questions that allow researchers to prepare for and respond to uncertainties and dilemmas built into their work.
                            </li>
                            <li className="listItem">
                                <b>Reflexivity</b> is about reflecting on the underlying motivations, assumptions and commitments that drive research projects. It commits researchers to inquire and challenge the taken-for-granted assumptions that structure their work and makes them attentive to alternative ways of framing the value and societal impact of their ideas, methods and proposed solutions.
                            </li>
                            <li className="listItem">
                                <b>Inclusion</b> is closely related to public engagement and stakeholder involvement. It is about involving relevant societal actors in research and innovation activities from an early stage, and ensuring continuous, open dialogue about desirable and undesirable outcomes throughout the project. Inclusion serves to broaden the ideas, perspectives and world-views that guide research and innovation activities.
                            </li>
                            <li className="listItem">
                                <b>Responsiveness</b> is about aligning research and innovation activities with the new perspectives, insights and values emerging through anticipatory, reflexive and inclusion-based RRI processes. Responsiveness presupposes a will to learn from practical experience and a capacity to translate this learning into better, more responsible research and innovation solutions.
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h2 className="subtitle">Keys</h2>
                </div>
                <p className="paragraph">
                    The RRI <i>keys</i> specify the core ingredients of responsibility in research and innovation projects. The thinking tool contains questions pertaining to the five following RRI keys: public engagement, open access, science education, gender and ethics.
                </p>
                <div className="infobox">
                    <div className="listContainer">
                        <ul>
                            <li className="listItem">
                                <b>Public engagement</b> is about engaging a broad range of societal actors in the research process, including researchers, industry, policy-makers and civil-society actors.
                            </li>
                            <li className="listItem">
                                <b>Open access</b> is about making research and innovation activities more transparent and easily accessible to the public, e.g. through open data and free access to publications.
                            </li>
                            <li className="listItem">
                                <b>Science education</b> is about increasing society’s general science literacy, e.g. by boosting children’s interest in science and technology, and by equipping civil society actors with the necessary skills to more actively take part in the research process.
                            </li>
                            <li className="listItem">
                                <b>Gender</b> is about promoting women’s participation as researchers and integrating a gender dimension into research content.
                            </li>
                            <li className="listItem">
                                <b>Ethics</b> is about fostering research activities of high societal relevance that comply to the highest ethical standards.
                            </li>
                        </ul>
                    </div>
                </div>
                <p className="paragraph">
                    Try our <a className="link" target="_blank" rel="noopener noreferrer" href="http://thinkingtool.eu/">thinking tool</a>, to see how the RRI conditions can be operationalized into reflective questions.
                </p>
                <p className="note paragraph">
                    Note: The text presented on this webpage is adapted from
                    &nbsp;
                    <a href="/Deliverable_6.1_Final_April 30_THINKING_TOOL.pdf" target="_blank" className="note-link">
                        Nielsen, M. W., Mejlgaard, N., Alnor, E., Griessler, E., & Meijer, I. (2018). Ensuring Societal Readiness: A thinking tool, Deliverable 6.1, NewHoRRIZON
                    </a>
                </p>
            </section>
            <footer className="dark-footer">
                <div>
                    <p className="paragraph note footer-text footer-text-top">
                        We need your feedback!
                        Comments, questions and suggestions for updates should be directed to Work Package leader, Professor Niels Mejlgaard (<a className="note-link" href="mailto:nm@ps.au.dk" target="_top">nm@ps.au.dk</a>)
                    </p>
                    <div className="footer-text-bottom">
                        <p className="paragraph note footer-text no-padding">
                            <b>How to Cite the Societal Readiness Thinking Tool:</b><br/>
                            <i>
                                ”Nielsen, M. W., Mejlgaard, N., Alnor, E., Bergmans, J., Bernstein, M., Birkving, A. L., P., Chan, T. T., Griessler, E., Jensen, M. F., van der Klippe, W., Kolby, S., Larsen, S. S., Lorentz, J., Meijer, I., Nicolaisen, P. B., Nieminen, M., Novitzky, P. (2019). The Societal Readiness Thinking Tool
                                [<a href="http://thinkingtool.eu/" className="note-link" target="_blank" rel="noopener noreferrer">http://thinkingtool.eu/</a> ]"</i>
                        </p>
                    </div>
                </div>
                <div className="footer-text darker-footer">
                    <b className="note">© 2019 Copyright:</b>
                    <a className="note-link" target="_blank" rel="noopener noreferrer" href="https://newhorrizon.eu/"> newhorrizon.eu/</a>
                </div>
            </footer>
        </div>
    </div>

  );
}

export default App;
