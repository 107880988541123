import React from 'react';
import 'react-bootstrap'
import { Link } from "react-scroll";
function Navbar() {
    return(
        <nav id="main-nav" className="navbar navbar-inverse fixed-top navbar-expand-lg navbar-light fixed-top" role="banner">
            <div className="nav-container">
                <div className="navbar-header responsive-logo">
                        <a className="navbar-brand"target="_blank" href="https://newhorrizon.eu" rel="home">

                        </a>
                </div>
                <div className="nav-menu">
                    <nav id="site-navigation"
                         itemType="http://schema.org/SiteNavigationElement">
                        <ul id="menu-main-menu" className="nav navbar-nav mr-auto flex-container">
                            <li className="no-padding">
                                <Link
                                    className="nav-item"
                                    activeClass="active"
                                    to="description"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration= {500}
                                >Introduction</Link>
                            </li>
                            <li className="no-padding">
                                <Link
                                    className="nav-item"
                                    activeClass="active"
                                    to="tool"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration= {500}
                                    >Thinking Tool</Link>
                            </li>
                            <li className="no-padding">
                                <Link
                                    className="nav-item"
                                    activeClass="active"
                                    to="methods"
                                    spy={true}
                                    smooth={true}
                                    offset={-50}
                                    duration= {500}
                                >About RRI</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;